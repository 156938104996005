import { NAMESPACES } from 'constants/locales'
import { BillingPeriod } from 'constants/pricing/types'

import useTranslation from 'next-translate/useTranslation'
import React, { memo, useCallback } from 'react'
import Link from 'components/atoms/link'
import { getPricingVersionFromPlans } from 'utils/pricing/plans'
import ContentSwitch from 'components/pricing-page/content-switch'
import { PlanSet } from 'components/pricing-page/types'
import dynamic from 'next/dynamic'

import PricingPlan from './pricing-plan'
import {
  Badge,
  BillingPeriodContainer,
  BillingPeriodToggle,
  BillingPeriodToggleWrapper,
  LinkContainer,
  PricingPlans,
  PricingPlansModuleContainer,
  StyledHeadlineModule,
} from './pricing-plans-module.styles'
import {
  TPricingPlansModuleProps,
  TYearlyDiscounts,
} from './pricing-plans-module.types'

const SiteEchoProvider = dynamic(() => import('utils/site-echo-provider'))

export const getYearlyDiscountTranslationKeyAndVars = (
  yearlyDiscounts: TYearlyDiscounts = {}
): [string, { discountPercentage: number } | undefined] => {
  const { smallestDiscount, biggestDiscount, multipleDiscounts } =
    yearlyDiscounts

  if (multipleDiscounts) {
    return ['pricing.billing.promo-savings-total-multiple-badge', undefined]
  }

  const hasDifferentDiscountsBetweenPlans = smallestDiscount !== biggestDiscount

  const i18nKey = hasDifferentDiscountsBetweenPlans
    ? 'pricing.billing.promo-savings-total-from-badge'
    : 'pricing.billing.promo-savings-total-badge'

  return [
    i18nKey,
    {
      discountPercentage: Math.floor(smallestDiscount ?? 0),
    },
  ]
}

const PricingPlansModule = ({
  plans,
  headlineModule,
  footerCta,
  focusedPlanName,
  billingPeriod = BillingPeriod.Yearly,
  yearlyDiscounts,
  showBillingPeriodToggle = true,
  showPlanDetailsLink = true,
  showDiscountsList = true,
  showCheckoutLink = true,
  showPlanSelector = false,
  planSet = PlanSet.Core,
  onBillingPeriodChange,
  onPlanSetChange,
  showPlanSelectorBadgeIn,
  planSelectorBadgeText,
  'data-testid': dataTestId,
  ...rest
}: TPricingPlansModuleProps) => {
  const { t } = useTranslation(NAMESPACES.PRICING)
  const pricingVersion = getPricingVersionFromPlans(plans)

  const handleSelectBillingPeriod = useCallback(() => {
    const newBillingPeriod =
      billingPeriod === BillingPeriod.Yearly
        ? BillingPeriod.Monthly
        : BillingPeriod.Yearly
    onBillingPeriodChange?.(newBillingPeriod)
  }, [billingPeriod, onBillingPeriodChange])

  const totalPlansCount = plans.length

  const Wrapper = showPlanSelector ? SiteEchoProvider : React.Fragment

  return (
    <Wrapper>
      <PricingPlansModuleContainer data-testid={dataTestId} {...rest}>
        {headlineModule && (
          <StyledHeadlineModule
            {...headlineModule}
            data-testid='pricing-plans-headline'
            id='pricing-plans-headline'
            isModuleChild
          />
        )}
        {showPlanSelector && (
          <ContentSwitch
            planSet={planSet}
            showPlanSelectorBadgeIn={showPlanSelectorBadgeIn}
            badgeText={planSelectorBadgeText}
            onClick={onPlanSetChange}
          />
        )}
        {showBillingPeriodToggle && (
          <BillingPeriodContainer hasHeadlineModule={!!headlineModule}>
            <BillingPeriodToggleWrapper data-testid='pricing-plans-billing-period-toggle'>
              <BillingPeriodToggle
                toggled={billingPeriod === BillingPeriod.Monthly}
                onClick={handleSelectBillingPeriod}
                position='left'
                disabled={billingPeriod === BillingPeriod.Monthly}
              >
                {t('pricing.billing.monthly')}
              </BillingPeriodToggle>
              <BillingPeriodToggle
                position='right'
                toggled={billingPeriod === BillingPeriod.Yearly}
                onClick={handleSelectBillingPeriod}
                disabled={billingPeriod === BillingPeriod.Yearly}
              >
                {t('pricing.billing.yearly')}
                {yearlyDiscounts && (
                  <Badge>
                    {t(
                      ...getYearlyDiscountTranslationKeyAndVars(yearlyDiscounts)
                    )}
                  </Badge>
                )}
              </BillingPeriodToggle>
            </BillingPeriodToggleWrapper>
          </BillingPeriodContainer>
        )}
        <PricingPlans {...rest}>
          {plans.map(plan => {
            return (
              <PricingPlan
                totalPlansCount={totalPlansCount}
                key={plan.planName}
                focused={plan.planName === focusedPlanName}
                plan={plan}
                billingPeriod={billingPeriod}
                showDiscountsList={showDiscountsList}
                showPlanDetailsLink={showPlanDetailsLink}
                showCheckoutLink={showCheckoutLink}
                pricingVersion={pricingVersion}
              />
            )
          })}
        </PricingPlans>
        {footerCta && (
          <LinkContainer>
            <Link
              href={footerCta.url}
              target={footerCta.target}
              onClick={footerCta.onClick}
            >
              {footerCta.text}
            </Link>
          </LinkContainer>
        )}
      </PricingPlansModuleContainer>
    </Wrapper>
  )
}

export default memo(PricingPlansModule)
