import styled, { css } from 'styled-components'
import {
  NeutralColors,
  UIPalette,
} from '@typeform/ginger/dist/constants/colors'
import mediaqueries from '@typeform/ginger/dist/util/mediaqueries'
import {
  fontFamily,
  fontSize,
  fontWeight,
  lineHeight,
} from '@typeform/ginger/dist/constants/typography'
import Button from 'components/atoms/button'
import { spacing } from '@typeform/ginger/dist/util/spacing'
import { GRID_UNIT } from '@typeform/ginger/dist/constants/design'

const ECHO_EMERALD = '#025041'
export const Container = styled.div<{
  totalPlansCount: number
}>`
  grid-column: 1 / -1;
  ${({ totalPlansCount }) => css`
    ${mediaqueries.sm`
      grid-column: ${totalPlansCount % 2 === 0 ? 'span 6' : '1 / -1'}
    `}

    ${mediaqueries.md`
      grid-column: span ${12 / totalPlansCount};
    `}
  `}
  margin-bottom: ${spacing(2)};
`

export const Card = styled.div<{
  focused: boolean
}>`
  box-shadow: 0 0 1px 2px rgba(25, 25, 25, 0.04);
  border-radius: 12px;
  padding: 28px;
  background-color: ${({ focused }) =>
    focused ? NeutralColors.GreyPaper : NeutralColors.White};

  ${({ focused }) =>
    focused &&
    css`
      border: 1px solid ${UIPalette.Emerald};
    `}

  display: flex;
  flex-direction: column;
  height: 100%;
`

const LeanP = styled.p`
  margin: 0;
  padding: 0;
  text-align: center;
  line-height: ${lineHeight.small};
`

export const Title = styled(LeanP)`
  margin-bottom: 4px;
  font-size: 18px;
  font-weight: ${fontWeight.medium};
  line-height: ${lineHeight.medium};
`

export const Overview = styled(LeanP)`
  margin-bottom: 24px;
  font-size: ${fontSize.x2Small};
  color: ${NeutralColors.Grey600};
  font-weight: ${fontWeight.regular};
  line-height: ${lineHeight.medium};

  ${mediaqueries.sm`
    margin-bottom: 0;
    min-height: 52px;
  `}

  ${mediaqueries.md`
    min-height: 64px;
  `}
`

export const PriceAndActionContainer = styled.div`
  margin-bottom: ${spacing(4)};
`

export const DiscountContainer = styled.div`
  margin-bottom: 18px;
  font-family: ${fontFamily.apercuPro};
  font-size: ${fontSize.xSmall};
  line-height: ${lineHeight.large};
  opacity: 0.8;
`

export const DiscountTitle = styled.span`
  font-weight: ${fontWeight.medium};
`

export const PriceContainer = styled.div<{
  shouldShowCheckoutLink: boolean
}>`
  min-height: 65px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-bottom: ${({ shouldShowCheckoutLink }) =>
    shouldShowCheckoutLink ? GRID_UNIT * 4 : 0}px;
`

export const PriceAlignment = styled.div`
  display: flex;
  align-items: baseline;
  gap: 4px;
  flex-wrap: wrap;
  justify-content: center;
`
export const Price = styled(LeanP)`
  margin: 0;
  padding: 0;
  font-size: ${fontSize.xLarge};
  font-weight: ${fontWeight.light};
  flex-shrink: 0;
`

export const BillingPeriodText = styled(LeanP)<{ focused: boolean }>`
  font-size: ${fontSize.x2Small};
  color: ${({ focused }) => (focused ? ECHO_EMERALD : NeutralColors.Grey600)};
`

export const YearlySaving = styled.span`
  font-weight: ${fontWeight.bold};
`

export const Frequency = styled(LeanP)`
  font-size: ${fontSize.x2Small};
  color: ${NeutralColors.Grey600};
`

export const Discount = styled(LeanP)`
  text-align: left;
`

export const Features = styled.ul`
  margin: 0;
  list-style: none;
  padding: 0;
  flex: 1;
`

export const Feature = styled.li<{ isGroup?: boolean }>`
  display: ${({ isGroup }) => (isGroup ? 'block' : 'flex')};
  align-items: flex-start;
  border-top: solid 1px ${NeutralColors.Grey100};
  padding-top: 12px;
  padding-bottom: 12px;

  ${({ isGroup }) =>
    isGroup &&
    css`
      padding-bottom: ${spacing(3)};
      &:last-child {
        padding-bottom: 0;
      }
    `}
`

export const GroupTitle = styled.span`
  display: inline-block;
  border-top: none;
  font-weight: ${fontWeight.medium};
  font-size: ${fontSize.xSmall};
  padding-bottom: 12px;
  line-height: 1;
`

export const IconContainer = styled.div`
  margin-right: 8px;
`

export const FeatureTitle = styled(LeanP)<{ groupTitle?: boolean }>`
  text-align: left;
  font-size: ${fontSize.xSmall};
  line-height: 1;
  flex-grow: 1;
  padding-top: 2px;

  strong {
    color: ${ECHO_EMERALD};
  }
`

export const Subtitle = styled(LeanP)`
  color: ${NeutralColors.Grey700};
`

export const PlanDetailsLink = styled.a`
  margin-top: ${spacing(3)};
  padding: ${spacing(1)};
  color: ${NeutralColors.Grey700};
  font-size: ${fontSize.xSmall};
  font-weight: ${fontWeight.medium};
  background: none;
  text-align: center;
  border: none;
  cursor: pointer;
  align-self: center;
`

export const CheckoutLink = styled(Button)`
  a {
    line-height: 1;
  }
`

export const DecorativeImageWrapper = styled.div`
  height: 65px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: ${spacing(4)};
`

export const DecorativeImage = styled.img`
  height: 100%;
`

export const FeatureListItemIcon = styled.span`
  margin-right: ${spacing(1)};
  display: inline-flex;
  justify-content: center;
  align-items: center;
`

export const LegalText = styled(LeanP)`
  text-align: center;
  color: ${NeutralColors.Grey600};
  font-size: ${fontSize.x2Small};
  margin-top: ${spacing(2)};
`
