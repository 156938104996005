import { BillingPeriod } from 'constants/pricing/types'

import { useState, useCallback } from 'react'

export type TStatelessComponentProps = {
  billingPeriod?: BillingPeriod
  onBillingPeriodChange?: (billingPeriod: BillingPeriod) => void
}

function withState<TInputComponentProps extends TStatelessComponentProps>(
  Component: React.ComponentType<TInputComponentProps>
): React.ComponentType<
  Omit<TInputComponentProps, 'billingPeriod'> & {
    defaultBillingPeriod?: BillingPeriod
  }
> {
  const ComponentWithState = (
    props: Omit<TInputComponentProps, 'billingPeriod'> & {
      defaultBillingPeriod?: BillingPeriod
    }
  ) => {
    const {
      defaultBillingPeriod = BillingPeriod.Yearly,
      onBillingPeriodChange: consumerOnBillingPeriodChange,
      ...rest
    } = props

    const [billingPeriod, setBillingPeriod] =
      useState<BillingPeriod>(defaultBillingPeriod)

    const onBillingPeriodChange = useCallback(
      (billingPeriod: BillingPeriod) => {
        setBillingPeriod(billingPeriod)
        consumerOnBillingPeriodChange?.(billingPeriod)
      },
      [setBillingPeriod, consumerOnBillingPeriodChange]
    )

    return (
      <Component
        {...(rest as TInputComponentProps)}
        billingPeriod={billingPeriod}
        onBillingPeriodChange={onBillingPeriodChange}
      />
    )
  }

  return ComponentWithState
}

export default withState
