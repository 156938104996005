import {
  GROWTH_PLAN_NAMES,
  PLANS_WITH_CUSTOM_PRICING,
  pricingPlans,
} from 'constants/pricing/general'
import { PUBLIC_ASSETS_BASE_URL } from 'constants/url'
import {
  TBillingPeriodDiscounts,
  TBackEndPricingPlan,
  TPricingPlansCodes,
  BillingPeriod,
} from 'constants/pricing/types'
import { TYPEFORM_ROOT_DOMAIN } from 'constants/public-envs'

import { formatNumberLocalized } from '@typeform/feature-set-matrix/lib/utils/format-number'
import {
  getActiveDiscountsPerPlan,
  getCurrencyCode,
  getDiscountedMonthlyPrice,
  translateDiscounts,
} from 'utils/pricing/price-calculations'
import { getCtaLink } from 'utils/pricing/links'
import { Translate } from 'next-translate'
import { TUser } from 'services/user-service/types'
import { TPricingModulePlan } from 'components/modules/pricing-plans-module'

export const formUid = 'PxcVKQGb'
export const source = 'website'
export const source2 = 'public%20pricing%20page'
export const source2ForGrowth = 'typeformforgrowth '
export const enterpriseCtaLinkBase = `https://tfsales.${TYPEFORM_ROOT_DOMAIN}/to/`

export const getEnterpriseLinkCta = (planName: TPricingPlansCodes) => {
  const isGrowthPlan = GROWTH_PLAN_NAMES.includes(planName)

  return `${enterpriseCtaLinkBase}${formUid}?source=${source}&source2=${
    isGrowthPlan ? source2ForGrowth : source2
  }`
}

const getGrowthEssentialsPlanMainFeatures = (t: Translate) => [
  {
    id: '1',
    title: t('pricing-page.features.group.includes'),
    items: [
      {
        id: 'members-seats',
        title: t('pricing-page.table.feature.members-seats-plural', {
          limitValue: '3',
        }),
      },
      {
        id: 'responses',
        title: t('pricing-page.features.monthly-responses', {
          limitValue: '1k+',
        }),
        tooltip: t('pricing-page.features.monthly-responses.tooltip'),
      },
      {
        id: 'response-enrichment',
        title: t('pricing-page.features.response-enrichment', {
          limitValue: '300',
        }),
        tooltip: t('pricing-page.features.response-enrichment.tooltip'),
      },
    ],
    isGroup: true,
  },
  {
    id: '2',
    title: t('pricing-page.features.group.features-youll-love'),
    items: [
      {
        id: 'video-questions',
        title: t('pricing-page.features.video-questions'),
      },
      {
        id: 'video-answers',
        title: t('pricing-page.features.video-answers'),
        tooltip: t('pricing-page.features.video-answers.tooltip'),
      },
      {
        id: 'spam-blocker-captcha',
        title: t('pricing-page.features.spam-blocker-captcha'),
      },
      { id: 'ask-ai', title: t('pricing-page.features.ask-ai') },
      {
        id: 'branding-removal',
        title: t('pricing-page.features.branding-removal'),
      },
      {
        id: 'custom-subdomain',
        title: t('pricing-page.features.custom-subdomain'),
      },
      {
        id: 'connect-with-120-integrations',
        title: t('pricing-page.features.connect-with-120-integrations'),
      },
    ],
    isGroup: true,
  },
]

const getGrowthProPlanMainFeatures = (t: Translate) => [
  {
    id: '1',
    title: t('pricing-page.features.group.includes'),
    items: [
      {
        id: 'members-seats',
        title: t('pricing-page.table.feature.members-seats-plural', {
          limitValue: '5',
        }),
      },
      {
        id: 'responses',
        title: t('pricing-page.features.monthly-responses', {
          limitValue: '10k+',
        }),
        tooltip: t('pricing-page.features.monthly-responses.tooltip'),
      },
      {
        id: 'response-enrichment',
        title: t('pricing-page.features.response-enrichment', {
          limitValue: '1,500',
        }),
        tooltip: t('pricing-page.features.response-enrichment.tooltip'),
      },
    ],
    isGroup: true,
  },
  {
    id: '2',
    title: t('pricing-page.features.group.everything-in-essentials'),
    items: [
      {
        id: 'multi-language-forms',
        title: t('pricing-page.features.multi-language-forms'),
      },
      {
        id: 'question-insights',
        title: t('pricing-page.features.question-insights'),
      },
      {
        id: 'salesforce-integration',
        title: t('pricing-page.features.salesforce-integration'),
      },
      {
        id: 'google-analytics-integration',
        title: t(
          'pricing-page.table.feature.insights-optimization.google-analytics-integration'
        ),
      },
      {
        id: 'gtm',
        title: t('pricing-page.table.feature.insights-optimization.gtm'),
      },
      {
        id: 'facebook-pixel',
        title: t('pricing-page.features.facebook-pixel'),
      },
      {
        id: 'priority-support-and-live-chat',
        title: t('pricing-page.features.priority-support-and-live-chat'),
      },
    ],
    isGroup: true,
  },
]

const getGrowthEnterprisePlanMainFeatures = (t: Translate) => [
  {
    id: '1',
    title: t('pricing-page.features.group.includes'),
    items: [
      {
        id: 'custom-number-of-seats',
        title: t('pricing-page.features.custom-number-of-seats'),
      },
      {
        id: 'responses',
        title: t('pricing-page.features.monthly-responses', {
          limitValue: '20k+',
        }),
        tooltip: t('pricing-page.features.monthly-responses.growth.tooltip'),
      },
      {
        id: 'response-enrichment',
        title: t('pricing-page.features.response-enrichment', {
          limitValue: '3,000',
        }),
        tooltip: t('pricing-page.features.response-enrichment.tooltip'),
      },
    ],
    isGroup: true,
  },
  {
    id: '2',
    title: t('pricing-page.features.group.everything-in-pro'),
    items: [
      {
        id: 'name-unlimited',
        title: t('pricing-page.feature.viewer-seats.name-unlimited'),
      },
      {
        id: 'custom-domain',
        title: t('pricing-page.table.feature.custom-domain'),
        tooltip: t('pricing-page.table.feature.custom-domain.tooltip'),
      },
      { id: 'sso', title: t('pricing-page.features.single-sign-on') },
      {
        id: '2fa',
        title: t('pricing-page.features.two-factor-authentication'),
      },
      {
        id: 'audit-log',
        title: t('pricing-page.features.audit-log'),
        tooltip: t('pricing-page.features.audit-log.tooltip'),
      },
      {
        id: 'hipaa-compliance',
        title: t('pricing-page.features.hipaa-compliance'),
        tooltip: t('pricing-page.features.hipaa-compliance.tooltip'),
      },
      {
        id: 'dedicated-customer-success-manager',
        title: t('pricing-page.features.dedicated-customer-success-manager'),
      },
    ],
    isGroup: true,
  },
]

export const getEnterprisePlanMainFeatures = (t: Translate) => [
  { id: '1', title: t('pricing-page.features.custom-responses') },
  { id: '2', title: t('pricing-page.features.vip-support'), highlighted: true },
  {
    id: '3',
    title: t('pricing-page.features.custom-domain'),
    highlighted: true,
  },
  {
    id: '4',
    title: t('pricing-page.features.single-sign-on'),
    highlighted: true,
  },
  {
    id: '5',
    title: t('pricing-page.features.gdpr-compliance'),
    highlighted: true,
  },
  {
    id: '6',
    title: t('pricing-page.features.custom-preferred-data-center'),
    highlighted: true,
  },
]

export const getLabelForCheckoutLink = (
  plan: TBackEndPricingPlan,
  t: Translate
) => {
  if (PLANS_WITH_CUSTOM_PRICING.includes(plan.planName)) {
    return t('pricing-page.plans.enterprise-cta-label')
  }

  return [t('pricing-page.body.cta-prefix'), t(plan.titleTranslationKey)]
    .filter(s => !!s)
    .join(' ')
}

const getHrefForPlan = (
  user: TUser | undefined,
  plan: TBackEndPricingPlan,
  t: Translate,
  billingPeriod: BillingPeriod
) => {
  if (PLANS_WITH_CUSTOM_PRICING.includes(plan.planName)) {
    return getEnterpriseLinkCta(plan.planName)
  }

  return getCtaLink({
    user,
    billingPeriod,
    planName: plan.planName,
    signupBaseUrl: t('common.cta.signup.url'),
  })
}

export const getMainFeaturesForPlan = (
  plan: TBackEndPricingPlan,
  t: Translate,
  lang?: string
) => {
  if (plan.planName === pricingPlans.enterprise) {
    return getEnterprisePlanMainFeatures(t)
  }

  // The main features for the Growth plans don't yet come from the API
  // So we have to hard-code them here
  if (plan.planName === pricingPlans.growth_essentials) {
    return getGrowthEssentialsPlanMainFeatures(t)
  }

  if (plan.planName === pricingPlans.growth_pro) {
    return getGrowthProPlanMainFeatures(t)
  }

  if (plan.planName === pricingPlans.growth_enterprise) {
    return getGrowthEnterprisePlanMainFeatures(t)
  }

  return plan.mainFeatures?.map(feature => {
    const limitValue = feature.limit_value
    let singularOrPlural = ''
    if (limitValue) {
      singularOrPlural = limitValue === 1 ? '.singular' : '.plural'
    }
    return {
      ...feature,
      title: t(
        `pricing-page.features.${feature.translationKey}${singularOrPlural}`,
        {
          limit_value: formatNumberLocalized(limitValue as number, lang),
        }
      ),
    }
  })
}

export const transformPlanData = (
  user: TUser | undefined,
  plans: Array<TBackEndPricingPlan>,
  t: Translate,
  handleUpgradeCTAClick: (
    billingPeriod: BillingPeriod,
    planName: TPricingPlansCodes
  ) => void,
  handleLearnMoreClick: (planName: TPricingPlansCodes) => void,
  discounts: TBillingPeriodDiscounts,
  lang?: string
): Array<TPricingModulePlan> => {
  const translatedDiscounts = {
    monthly: discounts?.monthly?.map(discount =>
      translateDiscounts(discount, t)
    ),
    yearly: discounts?.yearly?.map(discount => translateDiscounts(discount, t)),
  }

  const results = plans.map(
    plan =>
      ({
        ...plan,
        title: t(plan.titleTranslationKey),
        overview: t(plan.overviewTranslationKey),
        monthlyPrice:
          plan.pricing?.monthly && plan.pricing?.yearly
            ? {
                monthly: getDiscountedMonthlyPrice(
                  plan.pricing,
                  BillingPeriod.Monthly
                ),
                yearly: getDiscountedMonthlyPrice(
                  plan.pricing,
                  BillingPeriod.Yearly
                ),
                yearlySaving: plan.yearlySaving,
              }
            : null,
        currency: getCurrencyCode(plan.pricing),
        checkoutLink: {
          monthly: {
            label: getLabelForCheckoutLink(plan, t),
            href: getHrefForPlan(user, plan, t, BillingPeriod.Monthly),
            target: PLANS_WITH_CUSTOM_PRICING.includes(plan.planName)
              ? '_blank'
              : '_self',
            dataQa: `get-${plan.planName}-main`,
            onClick: () => {
              handleUpgradeCTAClick(BillingPeriod.Monthly, plan.planName)
            },
          },
          yearly: {
            label: getLabelForCheckoutLink(plan, t),
            href: getHrefForPlan(user, plan, t, BillingPeriod.Yearly),
            dataQa: `get-${plan.planName}-main`,
            target: PLANS_WITH_CUSTOM_PRICING.includes(plan.planName)
              ? '_blank'
              : '_self',
            onClick: () => {
              handleUpgradeCTAClick(BillingPeriod.Yearly, plan.planName)
            },
          },
        },
        planDetailsLink: {
          label: t('pricing-page.body.see-all-features'),
          ariaLabel: t('pricing-page.body.see-all-features-for-plan', {
            planName: t(`pricing-page.plans.${plan.planName}-plan-title`),
          }),
          onClick: () => handleLearnMoreClick(plan.planName),
        },
        mainFeatures: getMainFeaturesForPlan(plan, t, lang),
        decorativeImageSrc: PLANS_WITH_CUSTOM_PRICING.includes(plan.planName)
          ? `${PUBLIC_ASSETS_BASE_URL}/public/main-site/enterprise-bell.webp`
          : null,
        activeDiscounts: {
          monthly: getActiveDiscountsPerPlan(
            translatedDiscounts[BillingPeriod.Monthly],
            plan
          ),
          yearly: getActiveDiscountsPerPlan(
            translatedDiscounts[BillingPeriod.Yearly],
            plan
          ),
        },
      } as TPricingModulePlan)
  )

  return results
}
