import { HTMLAttributeAnchorTarget } from 'react'
import {
  TColumns,
  TSections,
} from '@typeform/ginger/dist/components/table/types'

import { currencies, pricingPlans } from './general'

export enum BillingPeriod {
  Yearly = 'yearly',
  Monthly = 'monthly',
}

export type TCurrenciesConstants = typeof currencies[keyof typeof currencies]
export type TPricingPlansCodes = typeof pricingPlans[keyof typeof pricingPlans]

export type TPricingVersion = '3.1' | '4.0'

// #region Features

type TResponsesAddonPricingForPeriod = {
  currency?: string
  responses?: number
  total?: number
}

export type TResponsesAddonPricing = {
  monthly?: Array<TResponsesAddonPricingForPeriod>
  yearly?: Array<TResponsesAddonPricingForPeriod>
}

export type TDiscountedPriceForPeriod = {
  currency: string
  total: number
}

export type TDiscountedPrice = {
  monthly: TDiscountedPriceForPeriod
  yearly: TDiscountedPriceForPeriod
}

export type TBackEndFeature = {
  id?: string
  name?: string
  description?: object | string
  isInPreviousPlan?: boolean
  limit_value?: number | string
  position?: number
  translationKey?: string
  max_value?: number
  highlighted?: boolean
  responsesAddonsPricing?: TResponsesAddonPricing
  discounted_price_per_step?: TDiscountedPrice
  step_value?: number
}

export type TBackEndFeatures = Array<TBackEndFeature>

export type TFrontEndFeature = TBackEndFeature & {
  title: string
}

export type TFrontEndFeatures = Array<TFrontEndFeature>

// #endregion

// #region Plans

type TCurrency = {
  code: string
}

export type TPrice = {
  amount: number
  base_amount: number
  currency: TCurrency
  scale: number
  policy: string
}

export type TPricingPlanPrice = {
  amount: number
  base_amount: number
  scale: number
  tax_amount: number
  tax_rate: number
  currency: TCurrency
  policy: string
}

export type TCheckoutLinkPerBillingPeriod = {
  label: string
  href: string
  target?: HTMLAttributeAnchorTarget
  dataQa?: string
  onClick: () => void
}

type TPricingApiPlanDetail = {
  id?: string
  addons?: Array<object>
  price?: TPrice
}

export type TDiscount = {
  name?: string
  nameTranslationKey?: string
  discountPercentages?: {
    business?: number
    plus?: number
    basic?: number
  }
  isPromotional?: boolean
}

export type TDiscounts = Array<TDiscount>

export type TBillingPeriodDiscounts = {
  yearly?: TDiscounts
  monthly?: TDiscounts
}

export type TCurrentPlan = {
  planName?: string
  period?: BillingPeriod
}

export type TPricingApiPricing = {
  [BillingPeriod.Monthly]: TPricingApiPlanDetail
  [BillingPeriod.Yearly]: TPricingApiPlanDetail
}

export type TPricingApiPricingPlan = {
  id: string
  listed?: boolean
  defaultBillingPeriod?: BillingPeriod
  features?: TBackEndFeatures
  isCurrent?: boolean
  highlighted?: boolean
  mainFeatures?: TBackEndFeatures
  planName: TPricingPlansCodes
  pricing?: TPricingApiPricing
  titleTranslationKey?: string
  overviewTranslationKey?: string
  color?: { base: string }
  pricing_version: TPricingVersion
}

type TBackEndPlanDetail = TPricingApiPlanDetail & {
  discountedPrice?: TPrice
}

export type TBackEndPricing = {
  [BillingPeriod.Monthly]: TBackEndPlanDetail
  [BillingPeriod.Yearly]: TBackEndPlanDetail
}

export type TBackEndPricingPlan = TPricingApiPricingPlan & {
  yearlySaving: number | null
  titleTranslationKey: string
  overviewTranslationKey: string
  pricing?: TBackEndPricing
  isEnterprisePlan?: boolean
}

// TODO: Use Ginger PricingModulePlan
export type TFrontEndPricingPlan = TBackEndPricingPlan & {
  title: string
  overview: string
  features: TFrontEndFeatures
  mainFeatures: TFrontEndFeatures
  checkoutLink: {
    monthly: TCheckoutLinkPerBillingPeriod
    yearly: TCheckoutLinkPerBillingPeriod
  }
  planDetailsLink?: {
    label: string
    href?: string
    ariaLabel?: string
    onClick?: () => void
  }
  monthlyPrice?: {
    yearly: number
    monthly: number
    yearlySaving: number | null
  }
  activeDiscounts: {
    yearly: Array<string>
    monthly: Array<string>
  }
  currency: string
  decorativeImageSrc?: string
}

// #endregion

// #region App Specific Objects

export type TUserDetails = {
  username: string
  userId: number
  accountId: number
  accountUid: string
}

// #endregion

// #region Pricing Comparison Table

export type TBackEndPricingComparisonDataColumn = {
  id: TPricingPlansCodes
}

export type TBackEndPricingComparisonDataRow = {
  id: string
  name: string
  type?: 'canBeUnlimited' | 'canBeBespoke' | 'check' | 'link'
  values: Record<string, number | string | null | boolean>
  url?: string
}

export type TBackEndPricingComparisonDataSection = {
  name: string
  rows: Array<TBackEndPricingComparisonDataRow>
}

export type TBackEndPricingComparisonData = {
  columns: Array<TBackEndPricingComparisonDataColumn>
  sections: Array<TBackEndPricingComparisonDataSection>
}

export type TFrontEndPricingComparisonData = {
  columns: TColumns
  sections: TSections
}

// #endregion
