import styled, { css } from 'styled-components'
import mediaqueries from '@typeform/ginger/dist/util/mediaqueries'
import { NeutralColors } from '@typeform/ginger/dist/constants/colors'
import { fontSize } from '@typeform/ginger/dist/constants/typography'
import Link from 'components/atoms/link'
import HeadlineModule from 'components/modules/headline-module'
import { spacing } from '@typeform/ginger/dist/util/spacing'
import { GridContainer } from 'components/atoms/layout/'
import ModuleContainer from 'components/modules/module-container'

export const PricingPlansModuleContainer = styled(ModuleContainer)`
  padding: 48px 0 86px;

  ${mediaqueries.md`
    padding: 98px 0;
  `}
`

export const StyledHeadlineModule = styled(HeadlineModule).attrs({
  forwardedAs: 'div',
})`
  margin-bottom: ${spacing(8)};
`

export const PricingPlans = styled(GridContainer)``

export const BillingPeriodContainer = styled.div<{
  hasHeadlineModule?: boolean
}>`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: ${({ hasHeadlineModule }) => (hasHeadlineModule ? 40 : 0)}px;
  margin-bottom: 40px;
`

export const BillingPeriodToggleWrapper = styled.div`
  border: 1px solid ${NeutralColors.Grey200};
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  z-index: 0;
  overflow: hidden;
`

export const Badge = styled.span`
  border: 1px solid rgba(2, 100, 81, 0.5);
  background-color: #e6efee;
  border-radius: 10px;
  padding-left: ${spacing(1.25)};
  padding-right: ${spacing(1.25)};
  line-height: 22px;
  margin-left: ${spacing(1)};
  font-size: ${fontSize.x2Small};
  color: #025041;
`

export const BillingPeriodToggle = styled.button<{
  toggled?: boolean
  position?: 'left' | 'right'
}>`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border: none;
  background: none;
  padding-left: ${spacing(1.5)};
  padding-right: ${spacing(1.5)};
  height: 30px;
  cursor: pointer;
  font-size: ${fontSize.xSmall};
  transition: color 0.2s, background-color 0.2s;
  color: ${NeutralColors.Ink};

  ${({ position }) =>
    position === 'left'
      ? css`
          border-top-left-radius: calc(8px - 1px);
          border-bottom-left-radius: calc(8px - 1px);
        `
      : css`
          border-top-right-radius: calc(8px - 1px);
          border-bottom-right-radius: calc(8px - 1px);
        `}

  ${({ toggled, position }) =>
    toggled
      ? css`
          cursor: default;
          color: ${position === 'right' ? '#025041' : NeutralColors.Ink};
          background-color: ${position === 'right'
            ? '#e6efee'
            : NeutralColors.Grey100};
        `
      : css`
          &:hover {
            background-color: ${NeutralColors.GreyPaper};
          }
        `}
`

export const LinkContainer = styled(GridContainer)`
  margin-top: 32px;
  justify-items: center;

  ${Link} {
    grid-column: 1 / -1;
  }
`
