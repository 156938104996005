import { NAMESPACES } from 'constants/locales'

import React, { useEffect, useRef, useState } from 'react'
import useTranslation from 'next-translate/useTranslation'

import { PlanSet } from '../types'

import {
  StyledContentSwitchContainer,
  StyledHighlightPill,
  StyledButton,
} from './content-switch.styles'
import { TContentSwitch } from './types'

export const ContentSwitch = ({
  onClick,
  planSet,
  sticky = true,
  showPlanSelectorBadgeIn = PlanSet.Growth,
  badgeText,
}: TContentSwitch) => {
  const { t } = useTranslation(NAMESPACES.PRICING)
  const stickyTriggerRef = useRef<HTMLDivElement>(null)
  const [isStickyTriggered, setIsStickyTriggered] = useState(false)
  const computedBadgeText = badgeText || t('pricing-page.content-switch.new')

  useEffect(() => {
    const stickyTriggerElement = stickyTriggerRef?.current
    if (!stickyTriggerElement) {
      return
    }

    const observer = new IntersectionObserver(
      ([entry]) => {
        setIsStickyTriggered(Number(entry?.boundingClientRect.top) < 88)
      },
      {
        rootMargin: '-88px 0px 0px 0px',
        threshold: 1.0,
      }
    )

    observer.observe(stickyTriggerElement)

    return () => {
      observer.unobserve(stickyTriggerElement)
    }
  }, [])

  const handleClick = (set: PlanSet) => () => {
    onClick?.(set)
  }

  return (
    <>
      {sticky && <div ref={stickyTriggerRef} />}
      <StyledContentSwitchContainer
        isStickyTriggered={isStickyTriggered}
        sticky={sticky}
      >
        <StyledButton
          onClick={handleClick(PlanSet.Core)}
          active={planSet === PlanSet.Core}
        >
          {t('pricing-page.content-switch.core-plans')}
          {showPlanSelectorBadgeIn === PlanSet.Core && (
            <StyledHighlightPill>{computedBadgeText}</StyledHighlightPill>
          )}
        </StyledButton>
        <StyledButton
          onClick={handleClick(PlanSet.Growth)}
          active={planSet === PlanSet.Growth}
        >
          {t('pricing-page.content-switch.growth-plans')}{' '}
          {showPlanSelectorBadgeIn === PlanSet.Growth && (
            <StyledHighlightPill>{computedBadgeText}</StyledHighlightPill>
          )}
        </StyledButton>
      </StyledContentSwitchContainer>
    </>
  )
}
