/*
 * Interpolated translation keys used in this file
 * pricing.billing.billed-monthly
 * pricing.billing.billed-yearly
 */
import { NAMESPACES } from 'constants/locales'
import { BillingPeriod } from 'constants/pricing/types'

import React, { memo } from 'react'
import useTranslation from 'next-translate/useTranslation'
import { ButtonSize } from 'components/atoms/button'
import { formatNumberLocalized } from '@typeform/feature-set-matrix/lib/utils/format-number'

import { TPricingModulePlan } from '../pricing-plans-module.types'

import {
  Card,
  Title,
  Overview,
  Frequency,
  Price,
  PlanDetailsLink,
  PriceAndActionContainer,
  DiscountContainer,
  Discount,
  PriceAlignment,
  BillingPeriodText,
  PriceContainer,
  CheckoutLink,
  DecorativeImage,
  DecorativeImageWrapper,
  DiscountTitle,
  Container,
  LegalText,
} from './pricing-plan.styles'
import PricingPlanFeatures from './pricing-plan-feature'

export type TPricingPlanProps = React.HTMLAttributes<HTMLDivElement> & {
  plan: TPricingModulePlan
  showDiscountsList?: boolean
  billingPeriod: BillingPeriod
  showPlanDetailsLink?: boolean
  focused?: boolean
  totalPlansCount: number
  showCheckoutLink?: boolean
  pricingVersion: string
}

const PricingPlan = ({
  plan,
  showDiscountsList = true,
  billingPeriod,
  showPlanDetailsLink = true,
  focused = false,
  totalPlansCount,
  showCheckoutLink,
  pricingVersion,
}: TPricingPlanProps) => {
  const { t, lang } = useTranslation(NAMESPACES.PRICING)
  const {
    title,
    overview,
    mainFeatures,
    planDetailsLink,
    currency,
    checkoutLink,
    activeDiscounts,
    monthlyPrice,
    decorativeImageSrc,
    isEnterprisePlan,
    planName,
  } = plan
  const monthlyPriceForBillingPeriod = monthlyPrice?.[billingPeriod]

  const activeDiscountsForBillingPeriod = activeDiscounts?.[billingPeriod]
  const checkoutLinkForBillingPeriod = checkoutLink?.[billingPeriod]

  const legalText = t(`pricing-page.typeform.plans.${planName}.legal`)
  const hasLegalText =
    legalText !== `pricing-page.typeform.plans.${planName}.legal`

  const shouldShowCheckoutLink =
    (showCheckoutLink || !!isEnterprisePlan) && !!checkoutLinkForBillingPeriod

  return (
    <Container totalPlansCount={totalPlansCount}>
      <Card focused={focused} data-testid='pricing-plan'>
        <Title data-testid='pricing-plan-title'>{title}</Title>
        <Overview data-testid='pricing-plan-overview'>{overview}</Overview>
        {decorativeImageSrc && (
          <DecorativeImageWrapper>
            <DecorativeImage src={decorativeImageSrc} alt='' />
          </DecorativeImageWrapper>
        )}
        {showDiscountsList && activeDiscountsForBillingPeriod?.length > 0 && (
          <DiscountContainer data-testid='pricing-plan-discounts'>
            <DiscountTitle>
              {t('pricing.billing.discount-list-heading')}
            </DiscountTitle>
            {activeDiscountsForBillingPeriod.map(discount => (
              <Discount key={discount}>{discount}</Discount>
            ))}
          </DiscountContainer>
        )}
        {(monthlyPriceForBillingPeriod || shouldShowCheckoutLink) && (
          <PriceAndActionContainer data-testid='pricing-plan-price'>
            {monthlyPriceForBillingPeriod && (
              <PriceContainer shouldShowCheckoutLink={shouldShowCheckoutLink}>
                <PriceAlignment>
                  <Price>
                    {formatNumberLocalized(monthlyPriceForBillingPeriod, lang)}{' '}
                    {currency}
                  </Price>
                  <Frequency>{t('pricing.price.month')}</Frequency>
                </PriceAlignment>
                <BillingPeriodText
                  focused={focused || billingPeriod === BillingPeriod.Yearly}
                >
                  {billingPeriod === BillingPeriod.Yearly &&
                  monthlyPrice.yearlySaving
                    ? t('pricing.price.saving.yearly', {
                        saving: formatNumberLocalized(
                          monthlyPrice.yearlySaving,
                          lang
                        ),
                        currency,
                      })
                    : t('pricing.price.total.yearly', {
                        price: formatNumberLocalized(
                          monthlyPriceForBillingPeriod * 12,
                          lang
                        ),
                        currency,
                      })}
                </BillingPeriodText>
              </PriceContainer>
            )}
            {shouldShowCheckoutLink && (
              <CheckoutLink
                data-testid='pricing-plan-checkout-link'
                href={checkoutLinkForBillingPeriod.href}
                size={ButtonSize.Medium}
                target={checkoutLinkForBillingPeriod.target || '_blank'}
                data-qa={checkoutLinkForBillingPeriod.dataQa}
                fullWidth
                onClick={checkoutLinkForBillingPeriod.onClick}
              >
                {checkoutLinkForBillingPeriod.label}
              </CheckoutLink>
            )}
          </PriceAndActionContainer>
        )}
        <PricingPlanFeatures
          features={mainFeatures}
          pricingVersion={pricingVersion}
        />
        {showPlanDetailsLink && planDetailsLink && (
          <PlanDetailsLink
            data-testid='pricing-plan-details-link'
            as={planDetailsLink.href ? 'a' : 'button'}
            href={planDetailsLink.href}
            aria-label={planDetailsLink.ariaLabel}
            onClick={planDetailsLink.onClick}
          >
            {planDetailsLink.label}
          </PlanDetailsLink>
        )}
      </Card>
      {hasLegalText && <LegalText>{legalText}</LegalText>}
    </Container>
  )
}

export default memo(PricingPlan)
