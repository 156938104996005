import {
  PLANS_WITH_CUSTOM_PRICING,
  billingPeriodCodes,
  checkoutLink,
  planNameQueryParam,
} from 'constants/pricing/general'
import { BillingPeriod } from 'constants/pricing/types'

import qs from 'qs'
import { getEnterpriseLinkCta } from 'components/pricing-page/plans-overview/plans-overview.data'

export const getPeriodCode = period =>
  period === BillingPeriod.Monthly
    ? billingPeriodCodes.monthly
    : billingPeriodCodes.yearly

export const EXPRESS_CHECKOUT_QUERY_PARAM = 'is_express_checkout'

const getSignupUrl = (baseUrl, periodCode, planName) => {
  const redirectionParams = qs.stringify({
    [planNameQueryParam]: planName,
    period: periodCode,
    [EXPRESS_CHECKOUT_QUERY_PARAM]: true,
  })
  const redirectionUrl = `${checkoutLink}?${redirectionParams}`

  const signupParams = qs.stringify({
    redirect_uri: redirectionUrl,
  })

  const url = `${baseUrl}?${signupParams}`
  return url
}

const getCheckoutUrl = (periodCode, planName) => {
  const url = `${checkoutLink}?${planNameQueryParam}=${planName}&focused`
  return `${url}&period=${periodCode}`
}
export const getCtaLink = ({
  user,
  billingPeriod,
  planName,
  signupBaseUrl = '/signup/',
}) => {
  if (PLANS_WITH_CUSTOM_PRICING.includes(planName)) {
    return getEnterpriseLinkCta(planName)
  }

  return user
    ? getCheckoutUrl(getPeriodCode(billingPeriod), planName)
    : getSignupUrl(signupBaseUrl, getPeriodCode(billingPeriod), planName)
}
