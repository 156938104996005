import React, { memo, useCallback } from 'react'
import { CheckIcon, HelpIcon } from '@typeform/echo-assets'
import { Flex, Tooltip } from '@typeform/echo-ui'
import { trackPricingPlanTooltipHover } from 'components/tracking'

import {
  TPricingModuleFeature,
  TPricingModuleFeatures,
} from '../pricing-plans-module.types'

import {
  Feature,
  FeatureListItemIcon,
  FeatureTitle,
  Features,
  GroupTitle,
} from './pricing-plan.styles'

const SubFeature = ({
  isGroup,
  items,
  title,
  tooltip,
  pricingVersion,
  id,
}: TPricingModuleFeature) => {
  const handleTooltipToggle = useCallback(
    (isTooltipVisible: boolean) => {
      if (isTooltipVisible) {
        trackPricingPlanTooltipHover({
          featureId: id,
          pricingVersion,
          location: 'body:compare_plans',
        })
      }
    },
    [pricingVersion, id]
  )

  if (items) {
    return (
      <Feature isGroup={isGroup}>
        <GroupTitle dangerouslySetInnerHTML={{ __html: title }} />
        <Features>
          {items.map(subFeature => (
            <SubFeature key={subFeature.id} {...subFeature} />
          ))}
        </Features>
      </Feature>
    )
  }

  return (
    <Feature>
      <FeatureListItemIcon>
        <CheckIcon />
      </FeatureListItemIcon>
      <FeatureTitle dangerouslySetInnerHTML={{ __html: title }} />
      {tooltip && (
        <Tooltip delayDuration={0} onOpenChange={handleTooltipToggle}>
          <Tooltip.Content style={{ zIndex: '1000' }}>
            {tooltip}
          </Tooltip.Content>
          <Tooltip.Trigger>
            <Flex color={'neutral.grey.600'}>
              <HelpIcon size='16' />
            </Flex>
          </Tooltip.Trigger>
        </Tooltip>
      )}
    </Feature>
  )
}

export type TPricingPlanFeaturesProps = {
  features: TPricingModuleFeatures
  pricingVersion?: string
}

const PricingPlanFeatures = ({
  features,
  pricingVersion,
}: TPricingPlanFeaturesProps) => {
  return (
    <Features data-testid='pricing-plan-features'>
      {features.map(feature => (
        <SubFeature
          key={feature.id}
          {...feature}
          pricingVersion={pricingVersion}
        />
      ))}
    </Features>
  )
}

export default memo(PricingPlanFeatures)
