import styled, { css } from 'styled-components'
import { BORDER_RADIUS } from '@typeform/ginger/dist/constants/borders'
import { spacing } from '@typeform/ginger/dist/util/spacing'
import { NeutralColors } from '@typeform/ginger/dist/constants/colors'
import { fontWeight } from '@typeform/ginger/dist/constants/typography'
import { getMenuBackgroundColorStyles } from 'components/organisms/mega-menu/helpers/background'
import {
  HEADER_HEIGHT,
  MOBILE_HEADER_HEIGHT,
} from 'components/organisms/mega-menu'
import mediaQueries from '@typeform/ginger/dist/util/media-queries'

import { CONTENT_SWITCHER_HEIGHT } from './content-switch.constants'

export const StyledContentSwitchContainer = styled.section<{
  isStickyTriggered?: boolean
  sticky?: boolean
}>`
  display: flex;
  justify-content: center;
  top: ${MOBILE_HEADER_HEIGHT}px;
  z-index: 10;
  height: ${CONTENT_SWITCHER_HEIGHT}px;

  ${mediaQueries.md`
    top: ${HEADER_HEIGHT}px;
  `}

  ${({ sticky, isStickyTriggered }) =>
    sticky &&
    css`
      position: sticky;

      &:before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        z-index: -1;
        border-bottom: 1px solid;
        border-top: 1px solid;
        border-color: ${isStickyTriggered
          ? NeutralColors.Grey100
          : 'transparent'};
        transition: border-color 150ms;

        ${getMenuBackgroundColorStyles}
      }
    `}
`

export const StyledButton = styled.button<{
  active: boolean
}>`
  border: none;
  border-bottom: ${spacing(0.375)} solid transparent;
  background-color: transparent;
  padding: ${spacing(2.25, 0, 1.625)};
  font-size: ${spacing(1.75)};
  font-weight: ${fontWeight.regular};
  line-height: ${spacing(2.5)};
  border-color: ${props =>
    props.active ? `${NeutralColors.Grey800}` : 'transparent'};
  color: ${NeutralColors.Ink};

  &:hover {
    cursor: pointer;
    border-color: ${NeutralColors.Grey800};
  }
  &:first-child {
    margin-right: ${spacing(3.5)};
  }
`

export const StyledHighlightPill = styled.span`
  border: 1px solid rgba(2, 100, 81, 0.5);
  background-color: #e6efee;
  border-radius: ${BORDER_RADIUS.medium};
  padding: ${spacing(0.25, 1.25)};
  margin-left: ${spacing(1)};
  color: #025041;
`
