import { pricingPlans } from 'constants/pricing/general'
import { BillingPeriod } from 'constants/pricing/types'

import get from 'lodash.get'

// Yearly discount is equivalent of 2 months / 12 months.
// Multiplied by 100 to express as a percentage
export const PRICING_3_DOT_1_YEARLY_DISCOUNT_PERCENTAGE = 200 / 12

const isBilledYearly = billingPeriod => billingPeriod === BillingPeriod.Yearly

export const getDiscountedMonthlyPrice = (pricing, billingPeriod) => {
  const monthlyAmount = isBilledYearly(billingPeriod)
    ? pricing[BillingPeriod.Yearly].discountedPrice.base_amount / 12
    : pricing[BillingPeriod.Monthly].discountedPrice.base_amount

  return Math.ceil(monthlyAmount / 1000)
}

export const getBaseMonthlyPrice = pricing => {
  const monthlyAmount = pricing[BillingPeriod.Monthly].price.base_amount

  return Math.ceil(monthlyAmount / 1000)
}

export const getActiveDiscounts = (discounts, planName) =>
  discounts.map(discount => ({
    ...discount,
    name: discount.name,
    nameTranslationKey: discount.nameTranslationKey,
    discountPercentage: discount.discountPercentages[planName],
  }))

export const getTotalDiscountValues = discounts => {
  if (!discounts?.length) {
    return { smallestDiscount: 0, biggestDiscount: 0, multipleDiscounts: false }
  }

  const perPlanDiscounts = {}
  const perPlanTotalDiscounts = {}

  discounts.forEach(({ discountPercentages }) => {
    Object.entries(discountPercentages).forEach(([planName, value]) => {
      perPlanDiscounts[planName] = perPlanDiscounts[planName] || []
      perPlanDiscounts[planName].push(value)
    })
  })

  Object.entries(perPlanDiscounts).forEach(([planName, values]) => {
    const totalDiscount =
      100 -
      values.reduce((previousTotalValue, discountValue) => {
        return previousTotalValue * ((100 - discountValue) / 100)
      }, 100)

    perPlanTotalDiscounts[planName] = totalDiscount
  })

  const smallestDiscount = Math.floor(
    Math.min(...Object.values(perPlanTotalDiscounts))
  )

  const biggestDiscount = Math.floor(
    Math.max(...Object.values(perPlanTotalDiscounts))
  )

  const multipleDiscounts = Object.entries(perPlanDiscounts).some(
    ([, values]) => values.length > 1
  )

  return { smallestDiscount, biggestDiscount, multipleDiscounts }
}

export const getCurrencyCode = pricing =>
  get(pricing, `${BillingPeriod.Monthly}.discountedPrice.currency.code`, 'USD')

export const calculateYearlySavingForPricing = pricing => {
  if (!pricing?.[BillingPeriod.Monthly] || !pricing?.[BillingPeriod.Yearly]) {
    return null
  }

  return (
    (12 * pricing[BillingPeriod.Monthly].price?.base_amount -
      pricing[BillingPeriod.Yearly].price?.base_amount) /
    1000
  )
}

export const calculatePromotionalDiscountModifier = (
  discounts,
  billingPeriod,
  planName
) => {
  const discountsForBillingPeriod = discounts[billingPeriod]

  const promotionalDiscounts = discountsForBillingPeriod.filter(
    discount => discount.isPromotional
  )

  const promotionalDiscountModifier = promotionalDiscounts.reduce(
    (currentModifier, discount) => {
      const discountPercentage = discount.discountPercentages[planName]
      const discountModifier = 1 - discountPercentage / 100

      return (currentModifier *= discountModifier)
    },
    1
  )
  return promotionalDiscountModifier
}

export const calculatePromotionalDiscountModifiers = (discounts, planName) => {
  const monthlyTotalDiscountModifier = calculatePromotionalDiscountModifier(
    discounts,
    BillingPeriod.Monthly,
    planName
  )
  const yearlyTotalDiscountModifier = calculatePromotionalDiscountModifier(
    discounts,
    BillingPeriod.Yearly,
    planName
  )
  return { monthlyTotalDiscountModifier, yearlyTotalDiscountModifier }
}

const deepClone = object => JSON.parse(JSON.stringify(object))

const clonePriceToDiscountedPrice = periodPricing => {
  periodPricing.discountedPrice = deepClone(periodPricing.price)
}

const applyTotalDiscountModifierToDiscountedPrice = (
  periodPricing,
  modifier
) => {
  periodPricing.discountedPrice.base_amount *= modifier
  periodPricing.discountedPrice.amount *= modifier
}

const applyTotalDiscountModifierToPricePeriods = (
  pricing,
  monthlyTotalDiscountModifier,
  yearlyTotalDiscountModifier
) => {
  clonePriceToDiscountedPrice(pricing.monthly)
  clonePriceToDiscountedPrice(pricing.yearly)

  applyTotalDiscountModifierToDiscountedPrice(
    pricing.monthly,
    monthlyTotalDiscountModifier
  )
  applyTotalDiscountModifierToDiscountedPrice(
    pricing.yearly,
    yearlyTotalDiscountModifier
  )
}

export const applyDiscountsToPricingModel = (pricingPlans, discounts) =>
  pricingPlans.map(plan => {
    if (!plan.pricing) {
      return plan
    }

    const modifiedPlan = deepClone(plan)

    const { monthlyTotalDiscountModifier, yearlyTotalDiscountModifier } =
      calculatePromotionalDiscountModifiers(discounts, plan.planName)

    applyTotalDiscountModifierToPricePeriods(
      modifiedPlan.pricing,
      monthlyTotalDiscountModifier,
      yearlyTotalDiscountModifier
    )

    return modifiedPlan
  })

export const getDiscountsMappingByBillingPeriod = discounts =>
  discounts.reduce(
    (mapping, discount) => {
      if (['both', 'yearly'].includes(discount.appliesTo)) {
        mapping.yearly.push(discount)
      }
      if (['both', 'monthly'].includes(discount.appliesTo)) {
        mapping.monthly.push(discount)
      }
      return mapping
    },
    { [BillingPeriod.Yearly]: [], [BillingPeriod.Monthly]: [] }
  )

export const applyRegularYearlyDiscount = discounts => {
  const regularYearlyDiscount = {
    nameTranslationKey: 'pricing-page.billing.yearly-plan',
    discountPercentages: {
      [pricingPlans.business]: PRICING_3_DOT_1_YEARLY_DISCOUNT_PERCENTAGE,
      [pricingPlans.plus]: PRICING_3_DOT_1_YEARLY_DISCOUNT_PERCENTAGE,
      [pricingPlans.basic]: PRICING_3_DOT_1_YEARLY_DISCOUNT_PERCENTAGE,
    },
    isPromotional: false,
  }

  discounts.yearly.push(regularYearlyDiscount)
  return discounts
}

export const getActiveDiscountsPerPlan = (discounts, plan) =>
  getActiveDiscounts(discounts, plan.planName)
    .filter(discount => discount.discountPercentage)
    .map(discount => discount.titles[plan?.planName])

export const translateDiscounts = (discount, t) => {
  return {
    ...discount,
    titles: Object.keys(discount.discountPercentages).reduce(
      (accTitles, planName) => {
        accTitles[planName] = t('pricing-page.billing.discount-list-item', {
          discountName: discount.name || t(discount.nameTranslationKey),
          discountPercentage: Math.floor(
            discount.discountPercentages[planName]
          ),
        })

        return accTitles
      },
      {}
    ),
  }
}
